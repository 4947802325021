import {ColumnsType} from "antd/lib/table";
import dayjs from "dayjs";

import {OrderRefund} from "../../../../types";
import {getFinalPrice} from "../../../../../../../shared/lib";

export const REFUNDS_COLUMNS: ColumnsType<OrderRefund> = [
    {
        title: 'Изображение',
        dataIndex: 'status_name',
        key: 'status_name',
    },
    {
        title: 'Наименование',
        dataIndex: '',
        key: 'name',
        render: (data) => data.name
    },
    {
        title: 'Возвращено, ₽',
        dataIndex: 'price',
        key: 'price',
        render: (price) => price ? getFinalPrice(price) : '-'
    },
    {
        title: 'Возвращено, шт',
        dataIndex: 'quantity',
        key: 'quantity',
        render: (quantity) => quantity ? quantity/100 : 0,
    },
    {
        title: 'Дата',
        dataIndex: 'created_at',
        key: 'created_at',
        render: record => dayjs(record).format('DD.MM.YYYY HH:mm'),
    },
    {
        title: 'Ответственный',
        dataIndex: 'client_name',
        key: 'client_name',
    },
];
import { AutoComplete, Form, Select } from 'antd';
import React, { useState } from 'react';
import { useMeasureMents } from 'src/entities/measurements/hooks/useMeasureMents';
import {FORM_AVAILABLE} from "src/entities/measurements/Measurements.constants";

import styles from '../OrderDelivery.module.less';

export const DeliveryForm: React.FC = () => {
  const { providers, form, order, isTariffsLoading, newDelivery, filteredAddresses, filteredTariffs, defaultValues } =
    useMeasureMents();
  const [search, setSearch] = useState<string>();
  const provID = Form.useWatch(['delivery', 'provID'], form);
  const tariffId = Form.useWatch(['delivery', 'tariff_id'], form);
  const disabled = !FORM_AVAILABLE.changeDelivery.includes(order!.order.status);

  const handleProviderChange = () => {
    form?.setFieldValue(['delivery', 'id'], undefined);
    form?.setFieldValue(['delivery', 'address'], undefined);
  };



  return (
      <div className={styles.group}>
        <p className={styles.groupTitle} style={{marginBottom: 12}}>Транспортная компания</p>
        <Form.Item
          label='Название'
          name={['delivery', 'provID']}
          rules={[
            {
              required: true,
              message: 'Обязательное поле',
            },
          ]}
        >
          <Select
            options={providers}
            placeholder='Выберите компанию'
            onChange={handleProviderChange}
            loading={isTariffsLoading}
            disabled={isTariffsLoading || disabled}
          />
        </Form.Item>
        <Form.Item
          label='Тариф'
          name={['delivery', 'tariff_id']}
          rules={[
            {
              required: true,
              message: 'Обязательное поле',
            },
          ]}
        >
          <Select
            options={
              filteredTariffs?.map(i => ({
                value: i.tariffId,
                label: i.displayName,
              })) || []
            }
            placeholder='Выберите тариф'
            loading={isTariffsLoading}
            disabled={isTariffsLoading || !provID || disabled}
          />
        </Form.Item>
        <Form.Item
          label='Адрес получения (доставки)'
          name={['delivery', 'address']}
          rules={[
            {
              required: true,
              message: 'Обязательное поле',
            },
          ]}
        >
          <AutoComplete
            options={filteredAddresses?.map(i => ({
              value: i.address,
              label: i.address,
            }))}
            onChange={setSearch}
            onSelect={() => setSearch(undefined)}
            placeholder='Введите адрес получения (доставки)'
            disabled={isTariffsLoading || !tariffId || disabled}
            filterOption={(_, option) => !search || option?.label.toLowerCase().includes(search.toLowerCase()) || false}
          />
        </Form.Item>
      </div>
  );
};

import {ColumnsType} from "antd/lib/table";
import dayjs from "dayjs";
import {getFinalPrice} from "src/shared/lib";

import {OrderTransactionItem} from "../../../../types";
import {STATUSES_COLORS} from "../../../../config";


export const TRANSACTIONS_COLUMNS: ColumnsType<OrderTransactionItem> = [
    {
        title: 'Транзакция',
        dataIndex: '',
        key: 'status_name',
        // @ts-ignore
        render: data => <span style={{color: STATUSES_COLORS[data.status]}}>{data.status_name}</span>
    },
    {
        title: 'Сумма',
        dataIndex: 'amount',
        key: 'amount',
        render: data => getFinalPrice(data)
    },
    {
        title: 'Дата',
        dataIndex: 'created_at',
        key: 'created_at',
        render: record => dayjs(record).format('DD.MM.YYYY HH:mm'),
    },
    {
        title: 'Описание',
        dataIndex: 'type_name',
        key: 'type_name',
        render: data => data || '',
    },
];
import { DateType } from 'src/shared/ui/datePicker';
import { Utils } from 'src/shared/lib';
import dayjs from "dayjs";
import { RightOutlined } from "@ant-design/icons";
import {Link} from "react-router-dom";
import {getCurrentDateTime, getTimeDiffs} from "src/shared/lib/getTimeDiffs";

import styles from './OrdersStatus.module.less';
import {OrdersDetailedHistory} from "../../types";


interface OrdersStatusProps {
  status: string;
  createdAt: DateType;
  history: OrdersDetailedHistory[];
}

export const OrdersStatus = ({ status, createdAt, history }: OrdersStatusProps) => (
   <article className={styles.ordersStatus}>
    <div className={styles.info}>
      <div className={styles.wrapper}>
        <h3 className={styles.title}>{status || '-'}</h3>
        <button type='button'>
          <RightOutlined />
        </button>
      </div>
      <div className={styles.wrapper} style={{marginBottom: 2}}>
        <p>{Utils.getFormatDate(createdAt, 'DD.MM.YYYY HH:mm')}</p>
        <Link to={`/profile/supplier/${history?.[0]?.user?.id}`} className={styles.link}>
          {history?.[0]?.user?.name}
        </Link>
      </div>
        <div className={styles.subInfo} style={{marginBottom: 0}}>
            <p>{`${getTimeDiffs(dayjs(history?.[0]?.created_at).toDate(), new Date(getCurrentDateTime()))} в статусе`}</p>
        </div>
    </div>
   </article>
 );

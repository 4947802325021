import {useSearchParams} from "react-router-dom";
import {MeasureMentsProvider} from "src/entities/measurements/hooks/useMeasureMents";
import {OrderMeasurements} from "src/shared/types";
import {useStore} from "effector-react";

import {OrderTasks} from "./orderTasks";
import {ORDER_TABS_KEYS} from "../../../config";
import {OrderStatuses} from "./orderStatuses";
import {OrderTransactions} from "./orderTransactions";
import {OrderRefunds} from "./orderRefunds";
import {OrderDelivery} from "./orderDelivery";
import {$order} from "../../../model";
import {getOrderFx, submitOrderMeasureReqData} from "../../../../ordersList/model";


const renderContent = (currentTab: string | null, id: string, props: any) => {
    switch (currentTab) {
        case ORDER_TABS_KEYS.DELIVERY: {
            return (
            <MeasureMentsProvider {...props}>
                <OrderDelivery />
            </MeasureMentsProvider>
            )
        }
        case ORDER_TABS_KEYS.REFUNDS: {
            return <OrderRefunds id={id}/>
        }
        case ORDER_TABS_KEYS.TRANSACTIONS: {
            return <OrderTransactions />
        }
        case ORDER_TABS_KEYS.STATUSES: {
            return <OrderStatuses />
        }
        default: {
            return <OrderTasks />
        }
    }
}
export const OrderTabsContent = ({id}: {id : string}) => {
    const [searchParams] = useSearchParams();
    const currentTab = searchParams.get('section');
    const order = useStore($order);
    const isOrderLoading = useStore(getOrderFx.pending);
    const handleSubmitMeasurements = (value: OrderMeasurements) => {
        if (order?.order?.id) {
            submitOrderMeasureReqData({id: order?.order?.id, data: {dimensions: value}});
        }
    };
    return renderContent(currentTab, id, {
        order,
        successHandler: handleSubmitMeasurements,
        isLoading: isOrderLoading
    })
}
import {ColumnsType} from "antd/lib/table";

import {OrdersDetailedHistory} from "../../../../types";
import dayjs from "dayjs";
import {userHistoryOptions} from "../../../../../../task/config";

export const STATUSES_COLUMNS: ColumnsType<OrdersDetailedHistory> = [
    {
        title: 'Статус',
        dataIndex: 'status_name',
        key: 'status_name',
    },
    {
        title: 'Дата',
        dataIndex: 'created_at',
        key: 'created_at',
        render: record => dayjs(record).format('DD.MM.YYYY HH:mm'),
    },
    {
        title: 'Роль',
        dataIndex: 'user',
        key: 'user.type',
        render: user => user?.type ? userHistoryOptions[user.type as keyof typeof userHistoryOptions] : '',
    },
    {
        title: 'Ответственный',
        dataIndex: 'user',
        key: 'user.name',
        render: user => user?.name || '',
    },
    {
        title: 'Описание статуса',
        dataIndex: 'description',
        key: 'description',
        render: data => data || '',
    },
];
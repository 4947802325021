import {PropsWithChildren} from "react";
import {Card, Col, Row} from "antd";
import {getFinalPrice} from "src/shared/lib";
import {useStore} from "effector-react";

import styles from "./OrderTransactions.module.less";
import {$order, $orderRefunds} from "../../../../model";


const CardWrapper = ({children}: PropsWithChildren) => (
    <Col style={{width: '20%'}}>
        <Card >
            {children}
        </Card>
    </Col>
)
export const OrderTransactionsCards = () => {
    const order = useStore($order);
    const orderRefunds = useStore($orderRefunds);
    const refundSum = order?.order?.check.filter((el) => el.refunded !== 0).reduce((prev, cur) =>
        prev + (cur.price * cur.refunded / 100), 0);
    const prices = order?.order?.prices;
    if (!prices) {
        return null;
    }
    const resultFinalPrice = <button type="button" className={styles.finalPrice} onClick={() => {
        navigator.clipboard.writeText(`${getFinalPrice(prices.now.products)}`)
    }}>
        {getFinalPrice(prices.now.products)}
    </button>
    const resultFinalMargin = <button type="button" className={styles.finalPrice} onClick={() => {
        navigator.clipboard.writeText(`${getFinalPrice(prices.now.margin)}`)
    }}>
        {getFinalPrice(prices.now.margin)}
    </button>
    const resultFinalDelivery = <button type="button" className={styles.finalPrice} onClick={() => {
        navigator.clipboard.writeText(`${getFinalPrice(prices.now.delivery)}`)
    }}>
        {getFinalPrice(prices.now.delivery)}
    </button>
    return (
        <Row>
            <CardWrapper>
                <div className={styles.infoContent}>
                        <span>
                          {prices.at_the_beginning.products === prices.now.products ? (
                              resultFinalPrice
                          ) : (
                              <>
                                  <span className={styles['beginning-price']}>{getFinalPrice(prices.at_the_beginning.products)}</span>
                                  {resultFinalPrice}
                              </>
                          )}
                            <span>{` товары`}</span>
                        </span>
                </div>
            </CardWrapper>
            <CardWrapper>
                <div className={styles.infoContent}>
                         <span>
                          {prices.at_the_beginning.margin === prices.now.margin ? (
                              resultFinalMargin
                          ) : (
                              <>
                                  <span className={styles['beginning-price']}>{getFinalPrice(prices.at_the_beginning.margin)}</span>
                                  {resultFinalMargin}
                              </>
                          )}
                             <span>{` комиссия`}</span>
                        </span>
                    <span className={styles.subInfo}>
                            {prices.at_the_beginning.margin_percent === prices.now.margin_percent ? (
                                `${prices.at_the_beginning.margin_percent}% от товаров`
                            ) : (
                                <>
                                    <span className={styles['beginning-price']}>{prices.at_the_beginning.margin_percent}%</span>
                                    {prices.now.margin_percent}
                                    %  от товаров
                                </>
                            )}
                        &nbsp;{prices.at_the_beginning.fixed_margin === prices.now.fixed_margin ? (
                        ` + ${getFinalPrice(prices.at_the_beginning.fixed_margin)}`
                    ) : (
                        <>
                            <span className={styles['beginning-price']}>+&nbsp;{getFinalPrice(prices.at_the_beginning.fixed_margin)}</span> (+&nbsp;
                            {getFinalPrice(prices.now.fixed_margin)}
                            )
                        </>
                    )}
                        </span>
                </div>
            </CardWrapper>
            <CardWrapper>
                <div className={styles.infoContent}>
                        <span>
                        {prices.at_the_beginning.delivery === prices.now.delivery ? (
                            resultFinalDelivery
                        ) : (
                            <>
                                <span className={styles['beginning-price']}>{getFinalPrice(prices.at_the_beginning.delivery)}</span>
                                {resultFinalDelivery}
                            </>
                        )}
                            <span>{` доставка`}</span>
                        </span>
                </div>
            </CardWrapper>
            <CardWrapper>
                <div className={styles.infoContent}>
                         <span>
                             <button type="button" className={styles.finalPrice} onClick={() => {
                                 navigator.clipboard.writeText(`${getFinalPrice(refundSum || 0)}`)
                             }}>
                                 {getFinalPrice(refundSum || 0)}
                             </button>
                             <span>{` возвраты`}</span>
                        </span>
                    <span className={styles.subInfo}>
                            {`${orderRefunds.length} возвратов`}
                        </span>
                </div>
            </CardWrapper>
            <CardWrapper>
                <div className={styles.infoContent}>
                         <span>
                             <button type="button" className={styles.finalPrice} onClick={() => {
                                 navigator.clipboard.writeText(`${getFinalPrice(prices.now.total)}`)
                             }}>
                                 {getFinalPrice(prices.now.total)}
                             </button>
                             <span>{` итого`}</span>
                        </span>
                    <span className={styles.subInfo}>
                            {`${getFinalPrice(prices.at_the_beginning.total)} при заказе`}
                        </span>
                </div>
            </CardWrapper>
        </Row>
    )
}
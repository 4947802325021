import {OrderTransactionsCards} from "./OrderTransactionsCards";
import {OrderTransactionsTable} from "./OrderTransactionsTable";

export const OrderTransactions = () => {
    return (
        <div>
           <OrderTransactionsCards />
           <OrderTransactionsTable />
        </div>
    )
}
import React, { useState } from 'react';
import {BranchesOutlined, RightOutlined, SettingOutlined} from '@ant-design/icons';
import { Measurements } from 'src/entities/measurements';
import { MainModal } from 'src/shared/ui';
import { OrderMeasurements } from 'src/shared/types';
import { Icons } from 'src/img/icons';

import { DetailedOrder } from '../../types';
import styles from './OrdersDelivery.module.less';
import { ConcatOrdersModal } from './components';
import { CONCAT_AVAILABLE_STATUS } from './OrdersDelivery.constants';
import {getFinalPrice} from "../../../../../shared/lib";

interface IProps {
  order: DetailedOrder;
  handleSubmitMeasurements: (value: OrderMeasurements) => void;
  isLoading: boolean;
  id?: string;
}

const getDeliveryIconByProvId = (id: number) => {
  switch (id) {
    case 1: {
      return <Icons.IconPostOffice />
    }
    case 2: {
      return <Icons.IconCdek />
    }
    default: {
      return <Icons.IconSelfDelivery />
    }
  }
}

export const OrdersDelivery: React.FC<IProps> = ({ order, handleSubmitMeasurements, isLoading, id }) => {
  const [showConcatModal, setShowConcatModal] = useState(false);
  const [isModal, setIsModal] = useState(false);
  console.log(order)
  const handleModal = (value: boolean) => {
    setIsModal(value);
  };

  const handleSuccess = (value: OrderMeasurements) => {
    handleSubmitMeasurements(value);
    setIsModal(false);
  };

  return (
    <article className={styles.delivery}>
      <div className={styles.wrapper}>
        <h3 className={styles.title}>
          {getDeliveryIconByProvId(order.order.delivery.provID)}
          <button type='button' onClick={() => {
            if (order?.order?.dispatch_number) {
              navigator.clipboard.writeText(order?.order?.dispatch_number)
            }
          }} className={order?.order?.dispatch_number && styles.copy_code}>
            {order?.order?.dispatch_number || '-'}
          </button>
          {` трек`}
        </h3>
        <button type='button'>
          <RightOutlined />
        </button>
      </div>
      <div className={styles.subInfo}>
        <p>{`${getFinalPrice(order?.order?.delivery?.price)} • ${order?.order?.address?.city || '-'}`}</p>
      </div>
      <div className={styles.subInfo} style={{marginBottom: 0}}>
        <p>{order?.order?.recipient?.name || '-'}</p>
      </div>
    </article>
  );
};

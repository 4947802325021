import {useStore} from "effector-react";
import {Empty, Table} from "antd";
import {getFinalPrice} from "src/shared/lib";

import {$order, $orderRefunds} from "../../../../model";
import styles from './OrdersRefunds.module.less';
import {REFUNDS_COLUMNS} from "./tableConfig";
import {OrderRefund} from "../../../../types";
import {OrderRefund as OrderRefundModal} from '../../../orderRefund'

export const OrderRefunds = ({id}: {id : string}) => {
    const orderRefunds = useStore($orderRefunds);
    const order = useStore($order);
    const groupedRefunds = orderRefunds.reduce((prev,refund) => {
        if (refund.created_at in prev) {
            prev[refund.created_at].push(refund)
        } else {
            prev[refund.created_at] = [refund];
        }
        return prev;
    }, {} as {[key: string]: OrderRefund[]});
    return (
        <div className={styles.wrapper}>
            <div className={styles.tableHeader}>
                <span className={styles.tableHeaderText}>
                    {`Всего ${Object.keys(groupedRefunds).length} возвратов на ${getFinalPrice(orderRefunds.reduce((prev, cur) => prev + cur.price, 0))}`}
                </span>
                {order?.order.status === 10 &&
                    <OrderRefundModal id={id}/>
                }
            </div>
            <Table
                columns={REFUNDS_COLUMNS}
                dataSource={orderRefunds}
                locale={{ emptyText: <Empty description="Нет данных" /> }}
            />
        </div>
    )
}
import {Menu} from "antd";
import {useSearchParams} from "react-router-dom";

import styles from './OrderTabs.module.less';
import {ORDER_TABS, ORDER_TABS_KEYS} from "../../config";
import {OrderTabsContent} from "./orderTabsContent";

export const OrderTabs = ({id}: {id : string}) => {
    const [searchParams,setSearchParams] = useSearchParams();
    const handleChangeTab = (tab: string) => {
        setSearchParams(params => {
            params.set("section", tab);
            return params;
        });
    }
    return (
        <div style={{paddingLeft: 8, paddingRight: 8, width: '100%'}}>
            <Menu mode='horizontal' className={styles.headerMenu} selectedKeys={[searchParams.get('section') || ORDER_TABS_KEYS.TASKS]}>
                {
                    ORDER_TABS.map(({label, key}) =>
                        <Menu.Item key={key} onClick={() => handleChangeTab(key)}>
                            {label}
                        </Menu.Item>
                    )
                }
            </Menu>
            <OrderTabsContent id={id}/>
        </div>
    )
}
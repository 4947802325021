import React from 'react';
import { Form, Input } from 'antd';
import { FORM_AVAILABLE, validationSchema } from 'src/entities/measurements/Measurements.constants';
import { useMeasureMents } from 'src/entities/measurements/hooks/useMeasureMents';
import {Icons} from "src/img/icons";

import styles from '../../../OrderDelivery.module.less';

type TProps = {
  readonly onRemove: () => void;
  readonly name: number;
};

export const ParcelsItem: React.FC<TProps> = ({ onRemove, name, ...restField }) => {
  const { order, form } = useMeasureMents();
  const field = form?.getFieldValue(['parcels', name]);

  return (
    <div className={styles.group}>
      <div className={styles.parcelHeader}>
          <div>
              <div className={styles.parcelTitleWrapper}>
                  <span className={styles.groupTitle}>
                      {name === 0 ? 'Посылка 1' : `Посылка ${name + 1}`}
                    </span>
                  {name > 0 && (
                  <button type='button' onClick={onRemove}>
                      <Icons.IconRedTrash />
                  </button>
              )}
              </div>
              {name === 0 && <span className={styles.subTitle}>
                  Oсновная, меняет статусы заказа
              </span>}
          </div>
      </div>
      {FORM_AVAILABLE.track.includes(order!.order.status) && (
        <>
          <Form.Item
            label='Внешний трек номер'
            name={[name, 'dispatch_number']}
            rules={[
              {
                required: true,
                message: 'Введите трек номер',
              },
            ]}
            {...restField}
          >
            <Input type='number' step={1} min={0} placeholder='Внешний трек номер' />
          </Form.Item>
          {field?.delivery_number && (
            <div className={styles.groupItem}>
              <span>Внутренний трек номер:</span>
              <span>{field?.delivery_number}</span>
            </div>
          )}
        </>
      )}
      <Form.Item
        label='Вес (в гр)'
        name={[name, 'dimensions', 'weight']}
        rules={[
          {
            required: true,
            message: 'Введите вес',
          },
          validationSchema.dimensions,
        ]}
        {...restField}
      >
        <Input type='number' step={1} min={0} placeholder='Только число' />
      </Form.Item>
      <div className={styles.wrapper}>
        <Form.Item
          label='Длина (в см)'
          name={[name, 'dimensions', 'length']}
          rules={[
            {
              required: true,
              message: 'Введите длину',
            },
            validationSchema.dimensions,
          ]}
          {...restField}
        >
          <Input type='number' step={1} min={0} placeholder='Только число' />
        </Form.Item>
        <Form.Item
          label='Ширина (в см)'
          name={[name, 'dimensions', 'width']}
          rules={[
            {
              required: true,
              message: 'Введите ширину',
            },
            validationSchema.dimensions,
          ]}
          {...restField}
        >
          <Input type='number' step={1} min={0} placeholder='Только число' />
        </Form.Item>
        <Form.Item
          label='Высота (в см)'
          name={[name, 'dimensions', 'height']}
          rules={[
            {
              required: true,
              message: 'Введите высоту',
            },
            validationSchema.dimensions,
          ]}
          {...restField}
        >
          <Input type='number' step={1} min={0} placeholder='Только число' />
        </Form.Item>
      </div>
    </div>
  );
};

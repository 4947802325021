import styles from "../OrderDelivery.module.less";
import React from "react";
import {useMeasureMents} from "src/entities/measurements/hooks/useMeasureMents";

export const PriceGroup = () => {
    const { order, newDelivery, defaultValues, form } =
        useMeasureMents();
    const parcels = form?.getFieldValue(['parcels']);
    const renderDelivery = ({ period, price, index = -1 }: { price?: number; period?: string; index?: number }) => (
        <div>
            <div className={styles.splitGroup}>
                <span className={styles.info}>Доставка{index >= 0 && ` (посылка ${index + 1})`}</span>
                <span>{price ? `${(price || 0) / 100}` : '-'} ₽</span>
            </div>
            <div className={styles.splitGroup}>
                <span className={styles.info}>Срок доставки</span>
                <span>{period || '-'}</span>
            </div>
        </div>
    );
    return (
        <>
        <div className={styles.group} style={{marginBottom: 12}}>
            <p className={styles.groupTitle} style={{marginBottom: 12}}>Итог</p>
            <span className={styles.info}>При оформлении клиентом:</span>
            {renderDelivery({
                price: order?.order?.delivery?.price,
                period: order?.order?.delivery.delivery_period,
            })}
        </div>

    {newDelivery &&
    (defaultValues?.delivery?.price !== newDelivery.price ||
        defaultValues?.delivery.delivery_period !== newDelivery.delivery_period) && (
        <div className={styles.group} style={{marginBottom: 12}}>
            <p className={styles.info}>С новыми данными:</p>
            {parcels?.length ? (
                <div className={styles.deliveryList}>
                    {parcels?.map((_: any, index: number) => (
                        <React.Fragment key={`parcel-${index}`}>
                            {renderDelivery({
                                price: newDelivery?.price,
                                period: newDelivery.delivery_period,
                                index,
                            })}
                        </React.Fragment>
                    ))}
                </div>
            ) : (
                renderDelivery({
                    price: newDelivery?.price,
                    period: newDelivery.delivery_period,
                })
            )}
        </div>
    )}
        </>
    )
}
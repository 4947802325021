import { ReactComponent as StatusActive } from './icon-status-active.svg';
import { ReactComponent as IconCdek } from './icon_cdek.svg';
import { ReactComponent as IconPostOffice } from './icon_post_office.svg';
import { ReactComponent as IconSelfDelivery } from './icon_self_delivery.svg';
import { ReactComponent as IconAgent } from './icon-agent.svg';
import { ReactComponent as IconShop } from './icon-shop.svg';
import { ReactComponent as IconRedTrash } from './icon-red-trash.svg';
import { ReactComponent as IconPlus } from './icon-plus.svg';
import { ReactComponent as IconCross } from './icon-cross.svg';

export const Icons = {
    StatusActive,
    IconCdek,
    IconPostOffice,
    IconSelfDelivery,
    IconAgent,
    IconShop,
    IconRedTrash,
    IconPlus,
    IconCross,
}
import {useStore} from "effector-react";
import {useState} from "react";
import {TOrderItemEntity} from "src/shared/api/queries/ordersApi";
import {Avatar, Card, Col, Row} from "antd";
import {ArrowLeftOutlined, EditOutlined, PictureOutlined, RightOutlined} from "@ant-design/icons";
import {getFinalPrice, Utils} from "src/shared/lib";
import {availableCancelIntermediary, getTaskStatusColor} from "src/utils";
import {Link} from "react-router-dom";
import {Icons} from "src/img/icons";
import {
    CancelIntermedaryModal
} from "src/entities/ordersProductsInfo/components/CancelIntermedaryModal";
import {ChangeStatusModal} from "src/entities/ordersProductsInfo/components/ChangeStatusModal";

import styles from './OrderTasks.module.less';
import {$order, submitCancelIntermedary, submitChangeStatus} from "../../../../model";
import {DetailedOrder, OrdersTask} from "../../../../types";


const OrderTask = ({ data, task} : {data: TOrderItemEntity, task: OrdersTask}) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [showIntermediaryModal, setShowIntermediaryModal] = useState<boolean>(false);
    const [showChangeStatusModal, setShowChangeStatusModal] = useState<boolean>(false);
    const taskColor = getTaskStatusColor(data.status);
    const size = task?.task?.offer_characteristics?.find(({code}) => code === 'size')?.value;
    return (
        <Card className={styles.orderTaskWrapper}>
            <div className={styles.orderTaskMainContentWrapper}>
                <div style={{border: `2px solid ${taskColor}`}} className={styles.avatarWrapper}>
                    <Avatar
                        className={styles.avatar}
                        icon={<PictureOutlined />}
                        src={data?.image || ''}
                    />
                </div>
                <div className={styles.orderTaskMainContent}>
                    <div className={styles.orderTaskHeader}>
                        <span className={styles.orderNumber}>
                            {`№ `}
                            <button type='button' className={styles.copyStyle} onClick={() => navigator.clipboard.writeText(data.code)}>
                                {data.code || '-'}
                            </button>
                        </span>
                        <div style={{color: taskColor}}>
                            <span style={{marginRight: 4}}>{data?.status_name || '-'}</span>
                            <span>
                                {data?.received_quantity ?? '-'}/{data?.quantity ?? '-'}
                            </span>
                        </div>

                    </div>
                    <div className={styles.orderPrice}>
                        <span>{data?.amount ? getFinalPrice(data.amount) : '-'}</span>
                        <span>{`(${getFinalPrice(data.price)} x ${data.quantity} шт)`}</span>
                    </div>
                    {
                        size ?
                            <span className={styles.size}>{`${size} размер`}</span>:
                            <span className={styles.noSize}>размер не указан</span>
                    }
                    <div className={styles.subInfoWrapper}>
                        <Icons.IconAgent />
                        {task?.intermediary?.id ? (
                            <Link to={`/profile/intermediary/${task.intermediary.id}`} state={{ prevPath: true }}>
                                {task.intermediary?.name || '-'}
                            </Link>
                        ) : (
                            <span>-</span>
                        )}
                        <span>
                            {task?.task?.take_in_work_date
                                ? Utils.getFormatDate(task.task.take_in_work_date, 'DD.MM.YYYY HH:mm')
                                : ''}
                          </span>
                        {availableCancelIntermediary.includes(data.status) && (
                            <EditOutlined onClick={() => setShowIntermediaryModal(true)} />
                        )}
                    </div>
                    <div className={styles.subInfoWrapper}>
                        <Icons.IconShop />
                        <Link to={`/profile/supplier/${data?.supplier_id}`} state={{ prevPath: true }}>
                            {data?.supplier_name}
                        </Link>
                        <span>{task?.pavilion?.name || '-'}</span>
                    </div>
                </div>
            </div>
            <div className={styles.expandGroup} style={{marginBottom: isExpanded ? 8 : 0}}>
                <button type='button' onClick={() => setIsExpanded(!isExpanded)}>
                    <span>Описание</span>
                    <RightOutlined style={{transform: isExpanded ? 'rotate(-90deg)' : 'rotate(90deg)'}}/>
                </button>
                <div />
                <Link to={`/task/${data.id}`}>
                    <span>К заданию</span>
                    <ArrowLeftOutlined style={{transform: 'rotate(180deg)'}}/>
                </Link>
            </div>
            {isExpanded && task?.post?.post_text &&
                <span className={styles.postText}>
                    {task?.post?.post_text}
                </span>
            }
            {isExpanded &&
                <div className={styles.sourceInfo}>
                    <p>
                        {task?.post?.url ? (
                            <a href={task?.post.url} target="_blank" rel="noreferrer">{`${task?.post.source.name || ''}`}</a>
                        ) : (
                            '-'
                        )}
                    </p>
                    <div>
                        <p>Выгружено {task?.post?.created_at ? Utils.getFormatDate(task?.post.created_at, 'DD.MM.YYYY HH:mm') : '-'}</p>
                        <p>Обновлено {task?.post?.updated_at ? Utils.getFormatDate(task?.post.updated_at, 'DD.MM.YYYY HH:mm') : '-'}</p>
                    </div>
                </div>
            }
            {showIntermediaryModal && (
                <CancelIntermedaryModal
                    isOpen={showIntermediaryModal}
                    setIsOpen={setShowIntermediaryModal}
                    confirmHandler={() => submitCancelIntermedary(data.id)}
                    item={{
                        data,
                        task,
                    }}
                />
            )}
            {showChangeStatusModal && (
                <ChangeStatusModal
                    isOpen={showChangeStatusModal}
                    setIsOpen={setShowChangeStatusModal}
                    confirmHandler={() => submitChangeStatus(data.id)}
                    item={{
                        data,
                        task,
                    }}
                />
            )}
        </Card>
    )
}

const buildTasksGridData = (items: TOrderItemEntity[], order: DetailedOrder) => {
    const resultData = [];
    for (let i = 0; i < items.length; i += 3) {
        const rowArr = [
            {data: items[i], task: order.tasks?.[i], index: i},
            {data: items[i + 1], task: order.tasks?.[i + 1], index: i + 1},
            {data: items[i + 2], task: order.tasks?.[i + 2], index: i + 2},
        ].filter(({data}) => !!data);
        resultData.push(rowArr);
    }
    return resultData;
}

export const OrderTasksGrid = ({items}: {items: TOrderItemEntity[]}) => {
    const order = useStore($order);
    if (!order) {
        return null;
    }
    const gridData = buildTasksGridData(items, order);
    return (
        <div className={styles.tasksGridWrapper}>
            {
                gridData.map((items) =>
                    <Row>
                        {items.map(({data, task}) =>
                            <Col span={8}>
                                <OrderTask data={data} task={task} />
                            </Col>
                        )}
                    </Row>
                )
            }
        </div>
    )
}
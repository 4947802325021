import {getFinalPrice} from "src/shared/lib";
import {useStore} from "effector-react";
import {useState} from "react";
import {TOrderItemEntity} from "src/shared/api/queries/ordersApi";

import {$order} from "../../../../model";
import {OrderTasksGrid} from "./OrderTasksGrid";
import styles from './OrderTasks.module.less';
import {OrderTasksFilters} from "./OrderTasksFilters";

export const OrderTasks = () => {
    const order = useStore($order);
    const prices = order?.order?.prices;
    const [filterStatuses, setFilterStatuses] = useState<Array<number>>([])
    if (!prices) {
        return null;
    }
    const resultFinalPrice = <button type="button" className={styles.finalPrice} onClick={() => {
        navigator.clipboard.writeText(`${getFinalPrice(prices.now.products)}`)
    }}>
        {getFinalPrice(prices.now.products)}
    </button>
    const items = order?.order?.items || [];
    const filteredItems = filterStatuses.length ? filterStatuses.reduce((prev, curr) => {
        items.forEach((item) => {
            if (item.status === curr) {
                prev.push(item)
            }
        })
        return prev;
    }, [] as TOrderItemEntity[]) : items;
    return (
        <div>
            <div className={styles.headerFilterWrapper}>
                <div className={styles.headerLeftContent}>
                    <span className={styles.headerTotal}>
                        {`Всего заданий на `}
                        <span>
                          {prices.at_the_beginning.products === prices.now.products ? (
                              resultFinalPrice
                          ) : (
                              <>
                                  <span className={styles['beginning-price']}>{getFinalPrice(prices.at_the_beginning.products)}</span>
                                  {resultFinalPrice}
                              </>
                          )}
                        </span>
                    </span>
                    <OrderTasksFilters filterStatuses={filterStatuses} setFilterStatuses={setFilterStatuses}/>
                </div>
            </div>
            <OrderTasksGrid items={filteredItems}/>
        </div>
    )
}
export const availableCancelIntermediary = [3, 14];
export const availableChangeStatus = [4, 7];

// Статус заказа | 
// 1:Не оплачен; 
// 2:Оплачен; 
// 3:В работе; 
// 4:Выкуплен; 
// 5:На складе; 
// 6:Сборка;
// 7:Оформление доставки; 
// 8:В пути; 
// 9:Доставлен в точку выдачи; 
// 10:Получен; 
// 11:Списание средств успешно; 
// 12:Списание средств не удалось; 
// 13:Ожидает курьера; 
// 14:Отклонен; 
// 15:Возврат; 
// 16:Нет в наличии
// 18:Возврат средств не удался; 
// 19:Разморозка средств не удалась; 
// 0:Отменен; 

// Статус задания| 
// 1:Не оплачено; 
// 2:Готово к работе; 
// 3:В работе; 
// 4:Выкуплено; 
// 5:Приемка; 
// 6:На складе; 
// 7:Товара нет в наличии; 
// 9:Снятие активных задач удаляющегося посредника; 
// 10:Выполнено; 
// 11:Проверка поставщиком; 
// 12:Сборка у поставщика; 
// 13:Ожидает посредника; 
// 14:Посредник подтвержден; 
// 15:Передан посреднику
// 0:Отменено;

export const getTaskStatusColor = (status: number) => {
    switch (status) {
        case 0: {
            return '#000000'
        }
        case 7:
        case 8: {
            return '#E50000'
        }
        case 4:
        case 5:
        case 10:
        case 6: {
            return '#008000'
        }
        default: {
            return '#108EE9'
        }
    }
}

import {useStore} from "effector-react";
import {getTaskStatusColor} from "src/utils";
import {Icons} from "src/img/icons";

import {$order} from "../../../../model";
import styles from './OrderTasks.module.less';


export const OrderTasksFilters = ({filterStatuses, setFilterStatuses}: {filterStatuses: Array<number>, setFilterStatuses: (statuses: Array<number>) => void}) => {
    const order = useStore($order);
    const items = order?.order?.items || [];
    const resultMap = items.reduce((prev, cur) => {
        if (cur.status in prev) {
            prev[cur.status] = {
                ...prev[cur.status],
                count: prev[cur.status].count + 1
            }
        } else {
            prev[cur.status] = {
                name: cur.status_name,
                count: 1
            }
        }
        return prev;
    }, {} as {[key: number]: {name: string, count: number}})

    return (
        <div className={styles.chipsWrapper}>
            {
                Object.keys(resultMap).map((el) => {
                    const color = getTaskStatusColor(+el);
                    const isActive = filterStatuses.includes(+el);
                    return (
                        <button type='button' className={styles.chip}
                                style={{border: `1px solid ${color}`, color: isActive ? 'white' : color, fontWeight: isActive ? 600 : 400, backgroundColor: isActive ? color : 'white'}}
                                onClick={() => {
                                    if (!filterStatuses.includes(+el)) {
                                        setFilterStatuses([...filterStatuses, +el])
                                    }
                                }}
                        >
                            <span>
                                {`${resultMap[+el].name} ${resultMap[+el].count}`}
                            </span>
                            {
                                isActive &&
                                <button type='button' style={{marginLeft: 4, display: 'flex', alignItems: 'center'}} onClick={(e) => {
                                    e.stopPropagation();
                                    setFilterStatuses(filterStatuses.filter((fs) => fs !== +el))
                                }}>
                                    <Icons.IconCross />
                                </button>
                            }
                        </button>
                    )
                })
            }
        </div>
    )
}
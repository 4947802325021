export const getCurrentDateTime = (): string => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    return`${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;  }

export const getTimeDiffs = (date1: Date, date2: Date): string => {
    const diffInMs = Math.abs(date2.getTime() - date1.getTime());
    const days = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diffInMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((diffInMs % (1000 * 60 * 60)) / (1000 * 60));
    return`${days ? `${days}д ` : ''}${hours ? `${hours}ч ` : ''}${minutes}м`;
};

import {TFormValues} from "src/entities/measurements/Measurements.types";
import {FORM_AVAILABLE} from "src/entities/measurements/Measurements.constants";
import {Button, Form} from "antd";
import {useState} from "react";
import {useMeasureMents} from "src/entities/measurements/hooks/useMeasureMents";

import {ParcelsForm} from "./ParcelsForm";
import {RecipientForm} from "./RecipientForm";
import {DeliveryForm} from "./DeliveryForm";
import {PriceGroup} from "./PriceGroup";
import styles from './OrderDelivery.module.less';

export const OrderDelivery = () => {
    const { closeHandler, order, form, defaultValues, fetchUpdate, onCloseForm } = useMeasureMents();
    const [loading, setLoading] = useState(false);

    const onSubmit = (values: TFormValues) => {
        setLoading(true);
        fetchUpdate?.({
            values,
            callback: () => {
                setLoading(false);
            },
            onSuccess: () => {
                closeHandler?.();
            }
        });
    };
    if (!order) {
        return null;
    }
    return (
        <div className={styles.measurements}>
            <Form<TFormValues>
                form={form}
                name='basic'
                labelCol={{
                    span: 24,
                }}
                wrapperCol={{
                    span: 24,
                }}
                initialValues={defaultValues}
                onFinish={onSubmit}
                autoComplete='off'
                disabled={loading}
            >
                <div className={styles.columnsWrapper}>
                    <div>
                        <div className={styles.columnHeader}>
                            1. Посылки
                        </div>
                        <ParcelsForm /> {/* !FORM_AVAILABLE.noDimensions.includes(order.order.status) */}
                    </div>
                    <div>
                        <div className={styles.columnHeader}>
                            2. Доставка
                        </div>
                        <RecipientForm />
                        <DeliveryForm />
                    </div>
                    <div>
                        <div className={styles.columnHeader}>
                            3. Итог
                        </div>
                        <PriceGroup />
                        <div className={styles.buttons}>
                            <Button onClick={onCloseForm} className={styles.button} disabled={loading}>
                                Отмена
                            </Button>
                            <Form.Item className={styles.submitBtnWrapper}>
                                <Button
                                    htmlType='submit'
                                    className={styles.submitBtn}
                                    type='primary'
                                    disabled={loading}
                                    loading={loading}
                                >
                                    Сохранить изменения
                                </Button>
                            </Form.Item>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    )
}
import {useStore} from "effector-react";
import {Table} from "antd"

import {$order} from "../../../../model";
import styles from './OrdersStatuses.module.less';
import {STATUSES_COLUMNS} from "./tableConfig";

export const OrderStatuses = () => {
    const order = useStore($order);
    const history = order?.history;
    return (
        <div className={styles.wrapper}>
            <Table
                columns={STATUSES_COLUMNS}
                dataSource={history}
            />
        </div>
    )
}
import {getFinalPrice} from 'src/shared/lib';
import { TOrderPrices } from 'src/shared/api/queries/ordersApi';
import {RightOutlined} from "@ant-design/icons";

import styles from './OrdersPrice.module.less';

interface OrdersPriceProps {
  prices: TOrderPrices;
  paymentType: string;
}

export const OrdersPrice = ({ prices, paymentType }: OrdersPriceProps) => (
  <article className={styles.ordersPrice}>
      <div className={styles.info}>
          <div className={styles.wrapper}>
              <h3 className={styles.title}>
                  <button type='button' onClick={() => {
                      if (prices.now.total) {
                          navigator.clipboard.writeText(`${getFinalPrice(prices.now.total)}`)
                      }
                  }} className={styles.copy_code}>
                      {getFinalPrice(prices.now.total) || '-'}
                  </button>
                  {` итого`}
              </h3>
              <button type='button'>
                  <RightOutlined />
              </button>
          </div>
          <div className={styles.subInfo}>
              <p>{`${getFinalPrice(prices.at_the_beginning.total)} при заказе`}</p>
          </div>
          <div className={styles.subInfo} style={{marginBottom: 0}}>
            <p>{`${paymentType} (${prices.now.margin_percent}%)`}</p>
          </div>
      </div>
  </article>
)

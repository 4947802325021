import {useStore} from "effector-react";
import {Empty, Table} from "antd";
import {getFinalPrice} from "src/shared/lib";

import {TRANSACTIONS_COLUMNS} from "./tableConfig";
import {$orderTransactions} from "../../../../model";
import styles from './OrderTransactions.module.less';
import {STATUSES_COLORS} from "../../../../config";


export const OrderTransactionsTable = () => {
    const orderTransactions = useStore($orderTransactions);
    if (!orderTransactions) {
        return null;
    }
    // @ts-ignore
    const currentStatus = <span style={{color: STATUSES_COLORS[orderTransactions[0]?.status]}}>{orderTransactions[0]?.status_name}</span>;
    return (
        <div className={styles.wrapper}>
            {!!orderTransactions.length && <span className={styles.currentTransaction}>
                {`Текущий статус платежа — `}
                {currentStatus}
                {` (${getFinalPrice(orderTransactions[0].amount)})`}
            </span>
            }
            <Table
                columns={TRANSACTIONS_COLUMNS}
                dataSource={orderTransactions}
                locale={{ emptyText: <Empty description="Еще нет транзакций" /> }}
            />
        </div>
    )
}
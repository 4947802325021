import React from 'react';
import { Form } from 'antd';
import {useMeasureMents} from "src/entities/measurements/hooks/useMeasureMents";
import {FORM_AVAILABLE} from "src/entities/measurements/Measurements.constants";
import {Icons} from "src/img/icons";

import { ParcelsItem } from './components';
import styles from '../OrderDelivery.module.less';


export const ParcelsForm: React.FC = () => {
  const { order } = useMeasureMents();

  return (
      <Form.List name='parcels'>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <ParcelsItem name={name} key={`parcel-${key}`} onRemove={() => remove(name)} {...restField} />
            ))}
            <button type='button' onClick={() => add()} disabled={!FORM_AVAILABLE.addParcel.includes(order!.order.status)} className={styles.addParcel}>
              <Icons.IconPlus />
                <span>Добавить ещё место</span>
            </button>
          </>
        )}
      </Form.List>
  );
};

import { Link } from 'react-router-dom';
import { getFormattedPhone } from 'src/shared/lib';

import styles from './OrdersBaseInfo.module.less';
import {initClientProfilePage, useClient} from "../../pages/profiles/clientsProfile/model";
import {useEffect} from "react";

interface OrdersBaseInfoProps {
  data: {
    id?: number;
    name: string;
    phone: string;
  };
}

export const OrdersBaseInfo = ({ data }: OrdersBaseInfoProps) => {
  const client = useClient();
  useEffect(() => {
    if (data.id) {
      initClientProfilePage(`${data.id}`);
    }
  }, [data.id])
  return (
    <article className={styles.info}>
      <div>
        <h3 className={styles.title}>
          <Link to={`/profile/client/${data.id}`} state={{ prevPath: true }}>
            {data.name}
          </Link>
        </h3>
        <span className={styles.subInfo}>
          {`${client?.total_orders || '-'} заказов, ${client?.active_orders || '-'} активных`}
        </span>
        <button type="button" className={styles.phone}>
          {getFormattedPhone(data.phone) || '-'}
        </button>
      </div>
    </article>
)};
